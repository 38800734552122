@import '~antd/dist/antd.css';
@import './theme.scss';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 12px !important;
  overflow: hidden;
}

ul,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin-bottom: 0;
}

// .ant-modal-title {
//   color: #1890FF;
// }

.common-block {
  margin: $custom-space $custom-space + 4;
  padding: 0 $custom-space $custom-space;
  border-radius: $border-radius-base;
  background: #fff;
}

.cb-no-title {
  padding-top: $custom-space;
}

.money-orange {
  color: orange;
}

.descriptions-bottom {
  padding-bottom: 0;
}

.common-result-container {
  //common-block
  margin: $custom-space $custom-space + 4;
  padding: 0 $custom-space $custom-space;
  border-radius: $border-radius-base;
  background: #fff;
  height: calc(100% - 32px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >:not(:first-child) {
    margin-top: 30px;
  }

  .bold {
    font-weight: bold;
    font-size: 16px;
  }
}

// 这里用global不起作用吗
// .descriptionsBold {
//   :global {
//     .ant-descriptions-item-label {
//       font-weight: bold;
//       font-size: 18px;
//     }

//     .ant-descriptions-item-content {
//       font-weight: bold;
//       font-size: 18px;
//     }
//   }
// }

.common-flex {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  overflow: hidden;
}

.common-flexs {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  overflow: hidden;
}

.common-scroll-view {
  flex: 1;
  height: 0;
  overflow-y: auto;
}

.common-center {
  // 居中
  align-items: center;
  justify-content: center;
}

.common-block-table {
  @extend .common-block;
  @extend .common-flex;
}

.common-block-tables {
  @extend .common-block;
  @extend .common-flex;
}

.common-link {
  display: inline-block;
  margin-left: $custom-space;
  color: $primary-color;
  font-size: 14px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

.common-white-space {
  height: $custom-space / 2;
}

.common-color65 {
  color: $black-65 !important;
}

.wingBlank {
  display: inline-block;
  width: $custom-space / 2;
}

.marginLeft8 {
  margin-left: 8px;
}

// 使用common-white-space替代whiteSpace，这里保留是为了兼容已使用的老项目
.whiteSpace {
  display: block;
  @extend .common-white-space;
}

:global {
  .ant-layout.ant-layout-has-sider {
    height: 100%;
  }

  .ant-select-item-option.ant-select-item-option-selected {
    color: $primary-color !important;
    background: #e6f7ff;
    font-weight: normal;
  }

  .ant-select-item-option {
    color: $black-65 !important;
  }

  .ant-message-notice-content {
    font-size: 14px;
  }

  .ant-input-suffix,
  .ant-picker-clear {
    color: $black-45;
  }

  .ant-upload-list-item-name {
    // upload控件 fileName color
    color: $black-65;
  }

  .ant-table-placeholder {

    // table 无数据时 去除底部线条
    .ant-table-cell {
      border-bottom: none !important;
    }
  }

  .ant-drawer-header {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    font-weight: 500;

    .ant-drawer-close {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  .ant-form-item {
    flex-wrap: nowrap !important;
    margin-bottom: 8px !important;
  }

  .ant-table-thead>tr>th {
    font-weight: 500;
  }

  .ant-picker {
    padding: 4px 7px 4px 11px;
  }

  .ant-col.ant-form-item-control {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .ant-select-disabled .ant-select-arrow,
    .ant-cascader-picker-disabled .ant-cascader-picker-arrow {
      display: none;
    }

    .ant-select-selection-item {
      display: block !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-select-disabled .ant-select-selection-item {
      padding-right: 0 !important;
    }

    .ant-input-disabled {
      padding-right: 11px !important;
    }

    .ant-cascader-picker-disabled .ant-cascader-picker-label {
      padding-right: 12px !important;
    }
  }

  .ant-modal-body {
    padding: 14px 24px 24px !important;
  }

  .ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px !important;
  }

  .ant-picker,
  .ant-input-affix-wrapper.ant-select-selection-search-input {
    padding-right: 8px !important;
  }

  .ant-form .ant-select-multiple .ant-select-selection-item-remove {
    vertical-align: super;
  }

  .ant-col-10 .ant-form-item-explain {
    margin-top: 0;
    margin-bottom: 0;
  }

}

::placeholder {
  font-weight: 400 !important;
  color: rgba(35, 37, 40, 0.85) !important;
  opacity: 0.4;
}

:-moz-placeholder {
  color: rgba(20, 20, 20, 0.27) !important;
}

::-moz-placeholder {
  color: rgba(20, 20, 20, 0.27) !important;
}

.second-button {
  margin-left: 10%;
}
