$custom-space: 16px;
$black: #232528;
$font-size-base: 14px;
$primary-color: #0079FF;
$error-color: #f5222d;
$menu-item-height: 40px;
$menu-item-boundary-margin: 0;
$menu-item-vertical-margin: 0;
$menu-collapsed-width: 60px;
$menu-item-font-size: 12px;
$menu-item-color: #606266;
$border-color-base: #EEE;
$btn-height-base: 32px;
$border-radius-base: 2px;
$form-item-margin-bottom: 12px;
$layout-header-height: 50px;
$layout-header-background: #FFF;
$layout-header-padding: 0 20px;
$black-85: rgba($color: $black,
    $alpha: 0.85);
$black-65: rgba($color: $black,
    $alpha: 0.65);
$black-45: rgba($color: $black,
    $alpha: 0.45);
$black-25: rgba($color: $black,
    $alpha: 0.25);